import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'
import axios from 'axios'

axios.defaults.baseURL = 'https://ci4.bungadiva.com/'

createApp(App).use(router).mount('#app')
