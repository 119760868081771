<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Here you will find some applications created with vue js
    </p>    
    <h3>The list of applications:</h3>
    <!-- <ul>
      <li>Calculator</li>
      <li>To Do App</li>
      <li><a href="https://chat.vuejs.org" target="_blank" rel="noopener">Community Chat</a></li>      
    </ul> -->
    <router-link to="/calculator">Calculator</router-link> | 
    <router-link to="/todo">To Do App</router-link> | 
    <router-link to="/dtodo">To Do App Dynamic</router-link> 
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
