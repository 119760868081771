<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/calculator">Calculator</router-link> | 
    <router-link to="/todo">To Do App</router-link> | 
    <router-link to="/dtodo">To Do App Dynamic</router-link>
  </nav>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
    beforeCreate() {
        // document.body.className = 'bg-vue-dark';
    },
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-vue-dark {
  background: #31475e;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
